<template>
   <div>
      <v-expand-transition>
         <v-form ref="form">
            <div>
               <v-card-text class="pt-9">
                  <v-row>
                     <v-col v-if="$store.state.user.code === 'SPA'" cols="12" md="3">
                        <v-select v-model="form.zone" :items="zones" label="Management Zone" placeholder="Zona Manajemen" outlined hide-details
                           item-text="name" item-value="zone"
                           :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                           />
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="12" md="4">
                        <v-select v-model="person" :items="persons" label="Person Maintenance" placeholder="Pilih Person" outlined hide-details
                           item-text="kind"
                           :return-object="true" :menu-props="{ bottom: true, offsetY: true }"
                           :disabled="!persons.length"
                           >
                           <template slot="append-outer">
                              <v-btn x-large icon tile color="accent" class="mr-1"
                                 :disabled="!$hasRole(option.kind.toLowerCase(), 'E') || !person || !person.actions.includes('E')"
                                 @click="exportRecord('person')"
                                 >
                                 <v-icon size="48">
                                    mdi-database-export
                                 </v-icon>
                              </v-btn>
                              <v-btn x-large icon tile color="accent" class="mr-1"
                                 :disabled="!$hasRole(option.kind.toLowerCase(), 'I') || !person || !person.actions.includes('I')"
                                 @click="importRecord('person')"
                                 >
                                 <v-icon size="48">
                                    mdi-database-import
                                 </v-icon>
                              </v-btn>
                              <v-btn large icon tile color="accent" width="48" height="48"
                                 :disabled="!$hasRole(option.kind.toLowerCase(), 'D') || !person || !person.actions.includes('D')"
                                 @click="removeRecord('person')"
                                 >
                                 <v-icon size="48">
                                    mdi-trash-can-outline
                                 </v-icon>
                              </v-btn>
                           </template>
                        </v-select>
                     </v-col>
                     <v-col cols="12" md="4">
                        <v-select v-model="location" :items="locations" label="Location Maintenance" placeholder="Pilih Location" outlined hide-details
                           item-text="kind"
                           :return-object="true" :menu-props="{ bottom: true, offsetY: true }"
                           :disabled="!locations.length"
                           >
                           <template slot="append-outer">
                              <v-btn x-large icon tile color="accent" class="mr-1"
                                 :disabled="!$hasRole(option.kind.toLowerCase(), 'E') || !location || !location.actions.includes('E')"
                                 @click="exportRecord('location')"
                                 >
                                 <v-icon size="48">
                                    mdi-database-export
                                 </v-icon>
                              </v-btn>
                              <v-btn x-large icon tile color="accent" class="mr-1"
                                 :disabled="!$hasRole(option.kind.toLowerCase(), 'I') || !location || !location.actions.includes('I')"
                                 @click="importRecord('location')"
                                 >
                                 <v-icon size="48">
                                    mdi-database-import
                                 </v-icon>
                              </v-btn>
                              <v-btn large icon tile color="accent" width="48" height="48"
                                 :disabled="!$hasRole(option.kind.toLowerCase(), 'D') || !location || !location.actions.includes('D')"
                                 @click="removeRecord('location')"
                                 >
                                 <v-icon size="48">
                                    mdi-trash-can-outline
                                 </v-icon>
                              </v-btn>
                           </template>
                        </v-select>
                     </v-col>
                  </v-row>
               </v-card-text>
               <v-divider />
               <v-card-text class="pb-6">
                  <v-row class="mt-0">
                     <v-col cols="12" class="py-0 d-flex">
                        <v-btn x-large color="accent" depressed class="mr-4" disabled @click="updateRecord">
                           Update
                        </v-btn>
                        <v-spacer />
                     </v-col>
                  </v-row>
               </v-card-text>
            </div>
         </v-form>
      </v-expand-transition>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ dialog.title }}</v-list-item-title>
                  <v-list-item-subtitle>Are you sure?</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { } from '@/utils/nirtara'
const empty = { kind: 'Database', zone: '' }
export default {
   name: 'NDatabaseForm',

   props: {
      option: { type: Object },
      zones: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
      persons: [{ kind: 'Member', actions: 'D' }], // { kind: 'Member', actions: 'IED' }
      person: '',
      locations: [{ kind: 'Building', actions: 'D' }], // { kind: 'Building', actions: 'IED' }
      location: '',
      dialog: { del: false, type: '', kind: '', title: '' },
   }),

   computed: {
   },
   watch: {
      'option.id' (id) {
         if (id <= 0) return
         this.form = Object.assign({}, this.option)
      },
   },
   mounted () {
      Object.assign(this.form, this.option)
   },
   methods: {
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
      exportRecord (type) {
         console.log(`exportRecord ({type: ${type[0].toUpperCase() + type.slice(1)}, kind: ${this[type].kind}})`)
      },
      importRecord (type) {
         console.log(`importRecord ({type: ${type[0].toUpperCase() + type.slice(1)}, kind: ${this[type].kind}})`)
      },
      removeRecord (type) {
         // console.log(`removeRecord ({type: ${type[0].toUpperCase() + type.slice(1)}, kind: ${this[type].kind}})`)
         const { dialog } = this
         dialog.type = type[0].toUpperCase() + type.slice(1)
         dialog.kind = this[type].kind
         dialog.title = `Delete All '${dialog.kind}'`
         dialog.del = true
      },
      deleteRecord () {
         const { dialog } = this
         // console.log(`deleteRecord ({type: ${dialog.type}, kind: ${dialog.kind}})`)
         this.$emit('delete', { type: dialog.type, kind: dialog.kind })
         this[dialog.type.toLowerCase()] = ''
         dialog.type = dialog.kind = ''
         dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
</style>
